
import { defineComponent } from 'vue';
import macLibrary from '@/components/view/common/mac-library';
import { isOffice } from '@/methods/role';

export default defineComponent({
    components: {
        macLibrary
    },
    setup() {
        return {
            isOffice: isOffice()
        };
    }
});
